import React from 'react';
import ReactMarkdown from 'react-markdown';

const ProductCompatibility = ({ data }) => (
  <div>
    <ReactMarkdown children={ data.documentGenerator.productDocument.compatibility } />
  </div>
)

export default ProductCompatibility;
