import React from 'react';

const MyWorks = () => (
  <div>
    <h4>My Works</h4>
    <p>
      <span>You might like my collection of Ionic products. See more at </span>
      <a href="https://market.ionic.io/user/66136" target="_blank" rel="noreferrer noopener">https://market.ionic.io/user/66136</a>
    </p>
    <p>
      <a href="https://market.ionic.io/user/66136" target="_blank" rel="noreferrer noopener"><img src="/images/banner.png" alt="See more of my works" /></a>
    </p>
  </div>
)

export default MyWorks;
