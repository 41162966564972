import React from 'react';

const ProductAnnouncement = () => (
  <div className="container mb-4">
    <div className="row justify-content-md-center">
      <div className="col-sm-4">
        <a href="https://takethatdesign.com" target="_blank" rel="noreferrer noopener">
          <img src="https://bit.ly/ttdads1" alt="takethatdesign.com thumbnail" style={{border: '1px solid #eee'}} />
        </a>
      </div>
      <div className="col-sm-6">
        <h3>TakeThatDesign.com is my new home</h3>

        <p>Click on the screenshot to visit, don't forget to bookmark, and subscribe to my newsletter. <br/>See you there!</p>
      </div>
    </div>
  </div>
)

export default ProductAnnouncement;
